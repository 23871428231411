<!-- 用户编辑弹窗 -->
<template>
  <el-dialog
    :title="'试题批量导入'"
    :visible="visible"
    width="60%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="下载模板" prop="level_id">
        <a :href="$baseImgUrl+'/Excel/recapitulate.xls'" >下载概括力模板</a>
      </el-form-item>
      <!--      等级-->
      <el-form-item label="等级" prop="level_id">
        <el-select :span="18"  v-model="ruleForm.level_id" placeholder="请选择等级">
          <el-option :span="18"
                     v-for="item in roleList"
                     :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Excel文件：" prop="file">
        <el-upload ref="upload"
                   accept=".xls,.xlsx"
                   action="#"
                   :auto-upload="true"
                   :multiple="false"
                   :file-list="fileList"
                   :before-upload="beforeUpload"
                   :http-request="uploadHttpRequest"
                   :on-remove="fileRemove"
                   :on-change="fileChange">
          <el-button size="small" type="primary">选择文件</el-button>
          <div slot="tip" class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
        </el-upload>
      </el-form-item>

    </el-form>
    <div slot="footer">
      <el-button
        @click="resetForm">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor';
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openImport: Function
  },
  components: {TinymceEditor},
  data() {
    return {
      // 表单数据
      init: {},
      fileList:[],
      // 表单验证规则domains
      ruleForm: Object.assign({
        level_id: '',
        file: '',
      }, this.data),
      // 等级下拉数据
      gradeSelection: [],
      rules: {
        level_id: [
          {required: true, message: '请选择等级', trigger: 'blur'}
        ],
        /*file: [
          {required: true, message: '请上传文件', trigger: 'blur'}
        ]*/
      },
      // 提交状态
      loading: false,
      // 角色列表
      roleList: [],

    }
  },
  //mounted
  watch: {
    //deep: true,
    data() {


    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http['post']('/Admin/recapitulateExcel', this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.ruleForm = {};
                this.fileList = [];
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm){
      // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
      // this.$emit('update:visible', false);
      // 调用父组件方法 隐藏弹框
      this.openImport(null)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    beforeUpload(file) {
      //文件类型
      const isType = file.type === 'application/vnd.ms-excel'
      const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const fileType = isType || isTypeComputer
      if(!fileType) {
        this.$message.error('上传文件只能是xls/xlsx格式！')
      }

      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 10;
      if(!fileLimit) {
        this.$message.error('上传文件大小不超过10M！');
      }
      return fileType && fileLimit
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      const formData = new FormData() //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append('file', param.file) //添加文件对象
      formData.append('uploadType', this.rulesType)
      this.$http['post']('upload/image/uploadFile/excel', formData).then(res => {
        this.loading = false;
        if (res.data.code === 200) {
          this.ruleForm.file = res.data.data
          this.$message({type: 'success', message: res.data.msg});
          param.onSuccess()
          /*const { data: { code, mark } } = res.data
          if(code === 0) {
            param.onSuccess()  // 上传成功的文件显示绿色的对勾
            this.uploadMark = mark
          }*/
         // return this.countData(this.uploadMark) //根据响应的 mark 值调用统计结果接口，返回一个promise以便进行链式调用
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    // 统计结果
    countFile(mark) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/operation/ruleImport/countData?mark=${mark}`)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 点击上传：手动上传到服务器，此时会触发组件的http-request
    submitUpload() {
      this.$refs.upload.submit()
    },
    // 文件发生改变
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
      }
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      if(fileList.length < 1) {
        this.uploadDisabled = true //未选择文件则禁用上传按钮
      }
    },
    // 取消
    closeDialog() {
      this.$refs.upload.clearFiles() //清除上传文件对象
      this.fileList = [] //清空选择的文件列表
      this.$emit('close', false)
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.roleList = res.data.data;
          //console.log(this.roleList)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
  }
}
</script>

<style scoped>
</style>
